"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForaBankPayments = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var react_redux_1 = require("react-redux");
var hooks_1 = require("../../../hooks");
var utils_1 = require("../../../utils");
var components_1 = require("../../../components");
var store_1 = require("../../../store");
require("./index.scss");
var ForaBankPayments = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var dispatch = (0, react_redux_1.useDispatch)();
    var paymentTab = (0, hooks_1.useSelector)(function (state) { return state.checkout.paymentTab; });
    var onChangeTab = function (paymentTab) { return dispatch((0, store_1.changePaymentTab)(paymentTab)); };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.SBPModal, {}), (0, jsx_runtime_1.jsx)(components_1.PaymentMethod, { onChange: onChangeTab, paymentTab: utils_1.PAYMENT_TABS.forabankSBP, tabText: t('SBP'), isActive: paymentTab === utils_1.PAYMENT_TABS.forabankSBP, icon: (0, jsx_runtime_1.jsx)(components_1.Icon, { name: "sbp" }) }), (0, jsx_runtime_1.jsx)(components_1.PaymentMethod, { onChange: onChangeTab, paymentTab: utils_1.PAYMENT_TABS.forabankCard, tabText: t('Card'), isActive: paymentTab === utils_1.PAYMENT_TABS.forabankCard, icon: (0, jsx_runtime_1.jsx)(components_1.Icon, { name: "card-pay" }) })] }));
};
exports.ForaBankPayments = ForaBankPayments;
