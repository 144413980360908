"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkoutSliceReducer = exports.clearCheckout = exports.clearCheckoutAfterPayment = exports.changeCachedStoreId = exports.changeForabankPayment = exports.changeSaveCard = exports.changeAmeriaCardBindingUuid = exports.changePaymentTab = exports.changeIsFutureDate = exports.changeCustomerDetails = exports.changeTableNumber = exports.changeOrderType = exports.changePickupSchedule = exports.changePickupAsap = exports.changeTipAmount = exports.changeTipPercent = exports.decreaseBasketProductQuantity = exports.increaseBasketProductQuantity = exports.updateProductFromBasket = exports.removeProductFromBasket = exports.addProductToBasket = exports.checkoutSlice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    orderType: '',
    products: [],
    customerDetails: { name: '', phone: '', email: '' },
    tipAmount: null,
    tipPercent: 10,
    isFutureDate: false,
    pickupAt: '',
    tableNumber: '',
    isPickupAsap: false,
    weekDayNumber: 0,
    cachedStoreId: null,
    paymentTab: null,
    notes: '',
    cutleryCount: null,
    ameriaCardBindingUuid: null,
    saveCard: false,
    forabankPayment: {},
};
exports.checkoutSlice = (0, toolkit_1.createSlice)({
    name: 'checkout',
    initialState: initialState,
    reducers: {
        addProductToBasket: function (state, action) {
            var lastItem = state.products[state.products.length - 1];
            var basketProductIndex = ((lastItem === null || lastItem === void 0 ? void 0 : lastItem.basketProductIndex) || 0) + 1;
            state.products.push(__assign(__assign({}, action.payload), { warningModifierId: null, basketProductIndex: basketProductIndex }));
        },
        removeProductFromBasket: function (state, action) {
            state.products = state.products.filter(function (product) { return product.basketProductIndex !== action.payload; });
        },
        updateProductFromBasket: function (state, action) {
            var basketProductIndex = state.products.findIndex(function (product) { return product.basketProductIndex === action.payload.basketProductIndex; });
            state.products[basketProductIndex] = action.payload;
        },
        increaseBasketProductQuantity: function (state, action) {
            var product = state.products.find(function (product) { return product.basketProductIndex === action.payload; });
            if (product) {
                product.quantity++;
            }
        },
        decreaseBasketProductQuantity: function (state, action) {
            var product = state.products.find(function (product) { return product.basketProductIndex === action.payload; });
            if (product) {
                product.quantity--;
            }
        },
        changeTipAmount: function (state, action) {
            state.tipAmount = action.payload;
        },
        changeTipPercent: function (state, action) {
            state.tipPercent = action.payload;
        },
        changeOrderType: function (state, action) {
            state.orderType = action.payload;
        },
        changeTableNumber: function (state, action) {
            state.tableNumber = action.payload;
        },
        changeCustomerDetails: function (state, action) {
            state.customerDetails[action.payload.key] = action.payload.value;
        },
        changePickupAsap: function (state, action) {
            state.pickupAt = action.payload;
            state.isPickupAsap = true;
            state.weekDayNumber = 0;
        },
        changePickupSchedule: function (state, action) {
            state.pickupAt = action.payload.pickupAt;
            state.weekDayNumber = action.payload.weekDayNumber;
            state.isPickupAsap = false;
        },
        changePaymentTab: function (state, action) {
            state.paymentTab = action.payload;
        },
        changeAmeriaCardBindingUuid: function (state, action) {
            state.ameriaCardBindingUuid = action.payload;
        },
        changeSaveCard: function (state, action) {
            state.saveCard = action.payload;
        },
        changeIsFutureDate: function (state, action) {
            return __assign(__assign({}, initialState), { customerDetails: state.customerDetails, cachedStoreId: state.cachedStoreId, isFutureDate: action.payload, pickupAt: '', isAsapTimeSelected: false, weekDayNumber: 0, products: [] });
        },
        changeCachedStoreId: function (state, action) {
            state.cachedStoreId = action.payload;
        },
        changeForabankPayment: function (state, action) {
            state.forabankPayment = action.payload;
        },
        clearCheckoutAfterPayment: function (state) { return (__assign(__assign({}, initialState), { customerDetails: state.customerDetails, tableNumber: state.tableNumber, orderType: state.orderType, isFutureDate: state.isFutureDate, cachedStoreId: state.cachedStoreId })); },
        clearCheckout: function (state) { return (__assign(__assign({}, initialState), { isFutureDate: state.isFutureDate, cachedStoreId: state.cachedStoreId })); },
    },
});
exports.addProductToBasket = (_a = exports.checkoutSlice.actions, _a.addProductToBasket), exports.removeProductFromBasket = _a.removeProductFromBasket, exports.updateProductFromBasket = _a.updateProductFromBasket, exports.increaseBasketProductQuantity = _a.increaseBasketProductQuantity, exports.decreaseBasketProductQuantity = _a.decreaseBasketProductQuantity, exports.changeTipPercent = _a.changeTipPercent, exports.changeTipAmount = _a.changeTipAmount, exports.changePickupAsap = _a.changePickupAsap, exports.changePickupSchedule = _a.changePickupSchedule, exports.changeOrderType = _a.changeOrderType, exports.changeTableNumber = _a.changeTableNumber, exports.changeCustomerDetails = _a.changeCustomerDetails, exports.changeIsFutureDate = _a.changeIsFutureDate, exports.changePaymentTab = _a.changePaymentTab, exports.changeAmeriaCardBindingUuid = _a.changeAmeriaCardBindingUuid, exports.changeSaveCard = _a.changeSaveCard, exports.changeForabankPayment = _a.changeForabankPayment, exports.changeCachedStoreId = _a.changeCachedStoreId, exports.clearCheckoutAfterPayment = _a.clearCheckoutAfterPayment, exports.clearCheckout = _a.clearCheckout;
exports.checkoutSliceReducer = exports.checkoutSlice.reducer;
